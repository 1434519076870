import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default function NotFoundPage() {
  return (
    <Layout className="no-sidebar">
      <SEO title="Seite nicht gefunden" />

      <div className="wrapper">
        <div className="container" id="main">
          <article id="content">
            <header>
              <h2>Seite nicht gefunden</h2>
            </header>

            <p>
              Leider ist unter dieser Adresse keine Seite vorhanden.
            </p>
          </article>
        </div>
      </div>
    </Layout>
  )
}
